<template>
  <div class="relative inline-block w-full">
    <label
      v-if="$slots.label && labeltop"
      :for="id"
      class="mb-1 block font-semibold"
    >
      <slot name="label" />
    </label>
    <template v-if="$slots.icon">
      <span class="absolute top-0 left-0 py-2 ml-3 h-full flex items-center">
        <slot name="icon" />
      </span>
    </template>
    <template v-if="props.clearButton && selectedValue">
      <span
        class="absolute top-0 right-2 py-2 ml-3 h-full flex items-center z-40"
      >
        <span
          type="button"
          tabindex="0"
          class="p-1 bg-neutral-50 rounded-lg cursor-pointer aspect-1/1"
          @click="clearInput"
          @keydown.enter="clearInput"
        >
          <UiIcon src="/svg/icons/cancel.svg" width="24" height="24" alt="delete" class="fill-neutral-800 rotate-180" />
        </span>
      </span>
    </template>
    <input
      :id="id"
      v-model="selectedValue"
      :type="type"
      :name="name"
      :title="title"
      :value="selectedValue"
      :class="[
        {'pl-11 pr-6': $slots.icon && !props.clearButton },
        {'pl-11 pr-11': $slots.icon && props.clearButton },
        {'px-4': !$slots.icon && !xs},
        {'py-3 h-[51.59px]': normal && !$slots.label},
        {'py-2 ': slim && !$slots.label},
        {'py-1 px-1': xs && !labeltop && !$slots.icon},
        {'py-3 text-base h-[55px] peer': normal && labeltop && $slots.label},
        {'py-3 text-base peer': slim && labeltop && $slots.label},
        {'pt-6 pb-1 text-lg font-semibold h-[55px] peer': normal && labelinside && $slots.label},
        {'pt-3 pb-1 text-base font-semibold peer': slim && labelinside && $slots.label},
        {'rounded-xl w-full': !props.inputstyle},
        {'border-neutral-200 bg-white': !error},
        {'border-cta-700 bg-cta-50': error},
        'border text-left focus:outline-none focus:border-petrol-500 focus:ring-1 focus:ring-petrol-500 block',
        props.inputstyle
      ]"
      :placeholder="props.placeholder"
      :aria-label="props.accessibilityLabel || props.title || props.name"
      :autocomplete="autocomplete ? 'on' : 'off'"
      @input="handleInput"
    >
    <label
      v-if="$slots.label && labelinside"
      :for="id"
      :class="[ {'absolute -mt-3 ml-4': labelup},
                {'absolute text-lg text-petrol-500 font-semibold duration-200 transform -translate-y-3.5 scale-[75%] top-4 z-10 origin-[0] left-11 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1 peer-focus:scale-[75%] peer-focus:-translate-y-3.5': slim && !labelup},
                {'absolute text-lg text-petrol-500 font-semibold duration-200 transform -translate-y-2.5 scale-[85%] top-4 z-10 origin-[0] left-11 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[85%] peer-focus:-translate-y-2.5': normal && !labelup},
                'left-0']"
    >
      <slot name="label" />
    </label>
  </div>
</template>
<script setup lang="ts">
import { getRandomId } from '~/utils/Utils'
const props = defineProps({
  design: {
    type: String,
    default: 'normal'
  },
  label: {
    type: String,
    default: ''
  },
  labelPosition: {
    type: String,
    default: 'inside'
  },
  accessibilityLabel: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: getRandomId
  },
  inputstyle: {
    type: [String, Array],
    default: ''
  },
  placeholder: {
    type: String,
    default: ' '
  },
  name: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  title: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  },
  error: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: Boolean,
    default: true
  },
  clearButton: {
    type: Boolean,
    default: false
  }
})
const normal = computed(() => props.design === 'normal')
const slim = computed(() => props.design === 'slim')
const xs = computed(() => props.design === 'xs')

// Label choices
const labelup = computed(() => props.label === 'up')
const labeltop = computed(() => props.labelPosition === 'top')
const labelinside = computed(() => props.labelPosition === 'inside')

const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>()

const selectedValue = ref(props.modelValue)

const handleInput = ({ target }: Event) => {
  emit('update:modelValue', (selectedValue.value = (target as HTMLInputElement).value))
}

const clearInput = () => {
  emit('update:modelValue', (selectedValue.value = ''))
}

watch(() => props.modelValue, (newValue) => {
  selectedValue.value = newValue
})

</script>
